export default class Email {
  id!: number
  from_name!: string // parte de quien vara
  reply_to_email!: string // remitente
  subject!: string // asunto
  message_body!: string // contenido del correo
  destination_type!: string // single | group
  destination_email!: string // correo de a quien va dirigido
  upload!: File
}
