var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-flex is-justify-content-center"},[_c('div',{staticClass:"form"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('Name of issuer')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + '  Julio Jaramillo',"type":"text"},model:{value:(_vm.objEmail.from_name),callback:function ($$v) {_vm.$set(_vm.objEmail, "from_name", $$v)},expression:"objEmail.from_name"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('Subject')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + '  Recordatorio',"type":"text"},model:{value:(_vm.objEmail.subject),callback:function ($$v) {_vm.$set(_vm.objEmail, "subject", $$v)},expression:"objEmail.subject"}})],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('Sender`s e-mail')}},[_c('b-input',{attrs:{"placeholder":_vm.$t('Ex:') + '  julio@.gmail.com.xd',"type":"text"},model:{value:(_vm.objEmail.reply_to_email),callback:function ($$v) {_vm.$set(_vm.objEmail, "reply_to_email", $$v)},expression:"objEmail.reply_to_email"}})],1)]}}],null,true)})],1),_c('div',{staticClass:"column"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('Type of destination')}},[_c('b-select',{attrs:{"placeholder":_vm.$t('Individual') + ' / ' + _vm.$t('Global'),"expanded":""},model:{value:(_vm.objEmail.destination_type),callback:function ($$v) {_vm.$set(_vm.objEmail, "destination_type", $$v)},expression:"objEmail.destination_type"}},[_c('option',{attrs:{"value":"single"}},[_vm._v("Individual")]),_c('option',{attrs:{"value":"crm"}},[_vm._v("crm")]),_c('option',{attrs:{"value":"group_test"}},[_vm._v("Grupo de prueba")])])],1)]}}],null,true)})],1)]),_c('div',{staticClass:"columns"},[(_vm.objEmail.destination_type == 'single')?_c('div',{staticClass:"column is-6"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{staticClass:"has-text-left",attrs:{"type":{ 'is-danger': errors[0], 'is-success': valid },"label":_vm.$t('Receiver`s email')}},[_c('b-input',{attrs:{"placeholder":"Correo del receptor","type":"text"},model:{value:(_vm.objEmail.destination_email),callback:function ($$v) {_vm.$set(_vm.objEmail, "destination_email", $$v)},expression:"objEmail.destination_email"}})],1)]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column"},[_c('vue-editor',{model:{value:(_vm.objEmail.message_body),callback:function ($$v) {_vm.$set(_vm.objEmail, "message_body", $$v)},expression:"objEmail.message_body"}})],1)]),_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button btn-save",attrs:{"loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.confirmation)}}},[_vm._v(" Enviar ")]),_c('button',{staticClass:"button btn-cancel",on:{"click":_vm.close}},[_vm._v("Cancelar")])])]}}])})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }