import authModule from '@/store/auth'
import { Component, Vue, Prop } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import http from '@/services/http'
import Email from '@/Models/Email'
import * as Helpers from '@/helpers'
import { VueEditor } from 'vue2-editor'
@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    VueEditor,
  },
})
export default class FormEmail extends Vue {
  @Prop({ required: false, default: null })
  objRow!: Email
  @Prop({ required: false, default: null })
  isAction!: string
  @Prop({ required: false, default: null })
  ownerMemberNumber!: number
  //el mae seleccionado
  objEmail: Email = new Email()
  loading = false
  date: any = new Date()
  name = ''
  isFileEdit = false

  get is_login(): boolean {
    return authModule.isAuthenticated
  }

  mounted() {
    if (this.objRow && this.isAction == 'resend') {
      this.$set(this, 'objEmail', this.objRow)
    }
  }

  async confirmation() {
    const Email = this.objEmail
    console.log(Email)
    this.isAction == 'add'
      ? await this.createObjEmail(Email)
      : await this.createObjEmail(Email)
  }
  async createObjEmail(objEmail: any) {
    try {
      await http.post(`/api/maillist/arcr_maillist_history/`, objEmail)
      this.$buefy.toast.open({
        message: 'Agregado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      console.error(error.response.data)
      this.loading = false
      /* this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      }) */
    }
  }
  /*  async updateObjEmail(objEmail: any) {
    console.log('editando', objEmail)
    try {
      await http.put(
        `/api/maillist/arcr_maillist_history/${this.objRow.id}/`,
        objEmail,
      )
      this.$buefy.toast.open({
        message: 'Actualizado',
        type: 'is-success',
      })
      setTimeout(() => {
        this.loading = false
        this.close()
      }, 1000)
    } catch (error: any) {
      this.loading = false
      this.$buefy.toast.open({
        message: error.response.data,
        type: 'is-danger',
      })
    }
  } */

  close() {
    this.objEmail = new Email()
    this.$emit('cleanForm')
  }
}
