import { Component, Vue } from 'vue-property-decorator'
import FormCCSS from '@/components/CCSSForm/CCSS/CCSS.vue'
import ModalDelete from '@/components/ModalDelete/ModalDelete.vue'
import http from '@/services/http'
import router from '@/router'
import * as Helpers from '@/helpers'
import Header from '@/components/Header/Header.vue'
import menuModule from '@/store/menu'
import Paginator from '@/components/Paginator/Paginator.vue'
import Item from '@/Models/Item'
import Email from '@/Models/Email'
import FormEmail from '@/components/EmailForm/Email.vue'
import Animation from '@/components/Animation/index.vue'

@Component({
  components: {
    FormCCSS,
    ModalDelete,
    Header,
    Paginator,
    FormEmail,
    Animation,
  },
})
export default class ViewEmail extends Vue {
  showList = true
  data: Array<Email> = []
  objEmail: Email = new Email()

  isAction = 'add'
  loading = false
  delete_modal_active = false
  url = '/api/maillist/arcr_maillist_history/'

  perPage = menuModule.perPage_param

  params: Array<Item> = []

  header: Array<any> = [
    {
      field: 'from_name',
      label: 'Emisor',
    },
    {
      field: 'subject',
      label: 'Asunto',
    },
    {
      field: 'destination_type',
      label: 'Tipo de envio',
    },
  ]
  editObj(props: any) {
    ;(this.$refs.header as any).showList = false
    this.objEmail = props
    this.showList = false
    this.isAction = 'resend'
  }
  changePerPage(range_perPage: any) {
    menuModule.setPerpageParam(String(range_perPage))
    this.perPage = menuModule.perPage_param
  }
  async deleteObj() {
    try {
      await http.delete(`/api/ccss/arcr_ccss/${this.objEmail.id}/`)
      this.data = this.data.filter((a: Email) => a.id !== this.objEmail.id)
      this.delete_modal_active = false
    } catch (error) {
      console.log(error)
      //toast.error("An error has occurred please try again.")
    }
  }
  deleteConfirm(props: any) {
    this.delete_modal_active = true
    this.objEmail = props
  }
  closeModal() {
    this.objEmail = new Email()
    this.delete_modal_active = false
  }

  openForm() {
    this.showList = false
  }
  cleanForm() {
    ;(this.$refs.header as any).showList = true
    //(this.$refs['headerForm'] as any)['showList'] = true
    this.objEmail = new Email()
    this.showList = true
    this.isAction = 'add'
  }

  filterItems(items: any) {
    this.data = items.map((obj: any) => ({
      ...obj,
      id: null,
    }))
    console.log(this.data)
  }

  async SearchFilter(search: string) {
    this.params = [] // se limpia el arreglo de params
    this.params.push({
      text: 'search', // nombre del parametro que se agrega a la url
      value: search, // valor del parametro
    })
    const currentPage = await (this.$refs['Paginator'] as any)['current'] //Se guarda la página actual
    await (this.$refs['Paginator'] as any).paginate(currentPage) // se ejecuta el metodo de paginar sobre la misma página actual pero ahora con los debidos parametros extras
  }
}
